<template lang="pug">
    v-card.border-g-e8.my-5(flat)
        v-card-title(v-if="title" primary-title)
            h3.headline {{ title }}

        v-card-text(:class="{ 'py-10': extraSpace, 'pt-0': noPadding }")
            slot
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        noPadding: {
            type: Boolean,
        },
        extraSpace: {
            type: Boolean,
        },
    },
}
</script>
