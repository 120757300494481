<template>
    <v-btn
        color="error"
        class="white--text"
        depressed
        :disabled="disabled"
        @click.stop="dialog = true"
    >
        <v-icon left>delete</v-icon>
        {{ $t('actions.delete') }}
        <confirm-modal
            v-model="dialog"
            :title="$t('texts.are_you_sure')"
            :approve-text="$t('actions.delete')"
            :cancel-text="$t('actions.cancel')"
            @onApprove="confirm"
        ></confirm-modal>
    </v-btn>
</template>

<script>
import ConfirmModal from '@/components/confirm/ConfirmModal'

export default {
    components: { ConfirmModal },
    props: {
        disabled: {
            type: Boolean,
        },
    },
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        confirm() {
            this.$emit('onDelete')
        },
    },
}
</script>
